import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { Row, Cell, Card, Icon, Section, Tabs } from "@sunwing/components";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { RCL } from "../../components/RCL";
import { useRouting } from "../../hooks";
import { cloudinaryImage } from "../../utils/cloudinary";
import { Loader } from "../../components/Loader";
import moment from "moment";
import { Breadcrumb } from "../../components/Breadcrumb";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./flyersVwq.module.scss");
  } else {
    styles = await import("./flyers.module.scss");
  }
})();

const Flyers = () => {
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const [selectedRegion, setSelectedRegion] = useState(config.isWestJetMode ? "Quebec/Québec" : 
    (locale === "fr" ? "Quebec/Québec" : "Ontario/Ontario")
  );
  const [tabIndex, setTabIndex] = useState("tabfly-0");
  const { oktaAuth } = useOktaAuth();
  const [flyersInfo, setFlyersInfo] = useState(null);

  const getFlyersInfo = async () => {
    const query =`
    query flyersData($locale: String, $skip:Int, $currentDate:DateTime, $brandCode:String!) {
      flyersCollection(locale: $locale, limit:100, skip:$skip, where:{expiryDate_gte:$currentDate, brand: {brandCode:$brandCode} }) {
        items {
          displayTitle
          promotionType
          flyerFileCollection {
            items {
              url
              size
              description
            }
          }
          startDate
          expiryDate
          regionName
          category
          flyerImageAsset
        }
      }
    }
  `;

  await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: query,
        variables: { 
          locale: locale === "fr" ? locale : "en-CA", 
          "skip":0,
          "currentDate":(new Date()).toISOString().substring(0, 10),
          brandCode:config.brand ? config.brand.toUpperCase() : 'SWG'
        },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }else{
        setFlyersInfo(data?.flyersCollection?.items);
        }
      });
  };

  const flyerCategories =
  flyersInfo && !!flyersInfo.length &&
    flyersInfo
      .map((flyer) => flyer.category[0])
      .filter((value, index, self) => self.indexOf(value) === index);

  const flyerRegions = config.isWestJetMode ? ["Quebec/Québec"] : [
    "Ontario/Ontario",
    "Western Canada/Canada De L’ouest",
    "Atlantic Canada/Canada Atlantique",
    "Quebec/Québec",
  ];

  const flyersWithTabsData =
    flyerCategories &&
    flyerCategories?.sort((a, b) => -a.localeCompare(b)).map((category, index) => {
      return {
        key: `tabfly-${index}`,
        panel: (
          <>
            {!flyersInfo && <Loader />}
            {flyersInfo !== null && (
              <div>
                <h2>
                  {locale === "fr"
                    ? category.split("/")[1]
                    : category.split("/")[0]}
                </h2>
                <Row cols={3}>
                  {flyersInfo
                    ?.slice()
                    .sort((a, b) => ((a.startDate !== null) && -a.startDate.localeCompare(b.startDate)))
                    .filter((fly) => fly.category[0] === category)
                    .filter((flyer) =>
                      flyer.regionName.includes(selectedRegion)
                    )
                    .map(
                      (flyer) =>                        
                        flyer?.flyerImageAsset !== null 
                        && ((flyer?.startDate==null) || ((flyer?.startDate !==null) && (moment().isAfter(flyer?.startDate))))
                        && flyer?.expiryDate!==null && moment().isBefore(flyer?.expiryDate) && (                          
                        <Cell key={flyer.displayTitle}>
                            <Card>
                              <Card.Image
                                className={styles.flyersImage}
                                src={cloudinaryImage(
                                  flyer?.flyerImageAsset?.[0]?.public_id,
                                  {
                                    width: 747,
                                  }
                                )}
                                alt="placeholder alt"
                              />
                              <Card.Body>
                                <p className={ styles.flyersPostDate }>
                                  {flyer.startDate ? dayjs(flyer.startDate, "YYYY-MM-D").format(
                                    "MMM D, YYYY"
                                  ) : <br/>}
                                </p>
                                <Card.Header
                                  as="p"
                                  size="p"
                                  weight="bold"
                                  className={styles.flyersCardHeading}
                                >
                                  {flyer.displayTitle}
                                </Card.Header>
                                <Card.Link
                                  className={ styles.flyersLink}
                                  target="_blank"
                                  to={
                                    !flyer.flyerFileCollection.items[0]
                                      ? ""
                                      : flyer.flyerFileCollection.items[0].url
                                  }
                                >
                                  <Icon
                                    className={styles.flyersDownloadLink}
                                    name="download"
                                    aria-hidden="true"
                                  />
                                  {RCL({ searchKey: "AgentTools_Download" })}
                                </Card.Link>
                              </Card.Body>
                            </Card>
                          </Cell>
                        )
                    )}
                </Row>
              </div>
            )}
          </>
        ),
        toggle: {
          iconComponent: <Icon className="custom-icon" name="children" />,
          label: {
            heading:
              locale === "fr" ? category.split("/")[1] : category.split("/")[0],
          },
        },
      };
    });

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/flyers" },
        fr: { path: "/fr/flyers" },
      });
    }
    getFlyersInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegionSelect = (e) => {
    setSelectedRegion(e.target.value);
  };

  useEffect(() => {
    if (config.isWestJetMode || (locale === "fr" && selectedRegion !== "Quebec/Québec")) {
      setSelectedRegion("Quebec/Québec");
    }
  }, [locale]);

  return (
    <div className={styles.flyers}>
      <Helmet>
        <title>{`Agent Portal - ${RCL({ searchKey: "Flyers_header" })}`}</title>
      </Helmet>
      {!config.isWestJetMode && flyersInfo && (
         <Breadcrumb pageSlug={"flyers"} locale={locale} />
      )}
      <Section
        title={RCL({ searchKey: "Flyers_header" })}
        titleAlignment="left"
        titleUppercase={false}
      >
        <p className={styles.flyersDesc}>
        {config.isWestJetMode ? <RCL searchKey="FlyersVwq_Desc" /> : <RCL searchKey="Flyers_Desc" />}
        </p>
        <div className={styles.flyersSelect}>
        {config.isWestJetMode ? 
          <div><RCL searchKey="regionfilter" /> { ": " + (locale === "fr" ? flyerRegions[0].split("/")[1] : flyerRegions[0].split("/")[0])}</div> : 
          (<>
            <label htmlFor="region">
              <RCL searchKey="regionfilter" />
            </label>
            
            <select
              id="regional"
              onChange={handleRegionSelect}
              value={selectedRegion}
            >
              {flyerRegions.map((region) => (
                <option value={region}>
                  {locale === "fr" ? region.split("/")[1] : region.split("/")[0]}
                </option>
              ))}
            </select>
          </>)}
        </div>
        {flyersWithTabsData && (
          <Tabs
            activeKey={tabIndex}
            data={flyersWithTabsData}
            tabIconLeftComponent={<Icon name="arrow-left" />}
            tabIconRightComponent={<Icon name="arrow-right" />}
            theme="generic"
            customTabWrapperClass="tabsWrapper"
            customPanelClass="tabsPanel"
            customActiveClass="tabsActive"
            className={styles.flyers}
          />
        )}
      </Section>
    </div>
  );
};

export default Flyers;
