const CLIENT_ID = process.env.CLIENT_ID;
const ISSUER = process.env.ISSUER;
const REDIRECT_URI = `${window.location.origin}`;
const CNT_APIM_AGENT_GQLURL = process.env.CNT_APIM_AGENT_GQLURL;
const CNT_APIM_AGENT_OPENURL = process.env.CNT_APIM_AGENT_OPENURL;
const CNT_APIM_RCL_OPENURL = process.env.CNT_APIM_RCL_OPENURL;
const AGT_API_URL = process.env.AGT_API_URL;
const AGT_STAR_URL = process.env.AGT_STAR_URL;
const AUTH_API_URL = process.env.AUTH_API_URL;
const VOUCHER_API_URL = process.env.VOUCHER_API_URL;
const CUSTOMER_API_URL = process.env.CUSTOMER_API_URL;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const APP_CODE = process.env.APP_CODE;

let HIS_API_URL = process.env.HIS_API_URL;

let USE_INTERACTION_CODE = false;
if (process.env.USE_INTERACTION_CODE === 'true') {
  USE_INTERACTION_CODE = true;
}

const params = new URL(window.location.href).searchParams;
const brand = params.get('brand');

switch (brand) {
  case 'swg':
    HIS_API_URL = process.env.HIS_API_URL;
    break;
  case 'vwq':
    HIS_API_URL = process.env.HIS_API_VWQ_URL;
    break;
  default:
      HIS_API_URL = process.env.HIS_API_URL;
    break; 
}

// eslint-disable-next-line
export default {
  appCode: APP_CODE,
  isWestJetMode : brand === 'vwq',
  brand : brand,
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'globalId'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
  },
  resourceServer: {
    contentfulApimAgentGqlUrl: CNT_APIM_AGENT_GQLURL,
    contentfulApimOpenAgentUrl: CNT_APIM_AGENT_OPENURL,
    contentfulApimOpenRclUrl: CNT_APIM_RCL_OPENURL,
    agentApiUrl: AGT_API_URL,
    agentStarUrl: AGT_STAR_URL,
    authApiUrl:AUTH_API_URL,
    hisApiUrl:HIS_API_URL,
    voucherApiUrl:VOUCHER_API_URL,
    customerApiUrl:CUSTOMER_API_URL
  },
};
