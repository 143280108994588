import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { Row, Cell, Card, Icon, Section, Tabs } from "@sunwing/components";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { RCL } from "../../components/RCL";
import { useRouting } from "../../hooks";
import { cloudinaryImage } from "../../utils/cloudinary";
import { Loader } from "../../components/Loader";
import moment from "moment";
import { Breadcrumb } from "../../components/Breadcrumb";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./MarketingToolkitsVwq.module.scss");
  } else {
    styles = await import("./MarketingToolkits.module.scss");
  }
})();

const MarketingToolkits = () => {
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const [selectedRegion, setSelectedRegion] = useState(config.isWestJetMode ? "Quebec/Québec" : 
    (locale === "fr" ? "Quebec/Québec" : "Ontario/Ontario")
  );
  const [tabIndex, setTabIndex] = useState("tabMarketingToolkit-0");
  const { oktaAuth } = useOktaAuth();
  const [marketingToolkitsInfo, setMarketingToolkitsInfo] = useState(null);

  const getMarketingToolkitsInfo = async () => {
    const query =`
    query marketingToolkitsData($locale: String) {
      marketingToolkitsCollection(locale: $locale) {
        items {
          displayTitle
          promotionType
          marketingToolkitsFileCollection {
            items {
              url
              size
              description
            }
          }
          startDate
          expiryDate
          regionName
          category
          marketingToolkitsImageAsset
        }
      }
    }
  `;

  await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: query,
        variables: { 
          locale: locale === "fr" ? locale : "en-CA", 
        },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }else{
          setMarketingToolkitsInfo(data?.marketingToolkitsCollection?.items);
        }
      });
  };

  const categories = new Set();

  if(marketingToolkitsInfo && marketingToolkitsInfo.length > 0) {
    marketingToolkitsInfo
      .forEach((marketingToolkit) => 
                marketingToolkit.category
                .forEach(cat => categories.add(cat)));
  }

  const marketingToolkitsCategories = [...categories];

  const marketingToolkitRegions = config.isWestJetMode ? ["Quebec/Québec"] : [
    "Ontario/Ontario",
    "Western Canada/Canada De L’ouest",
    "Atlantic Canada/Canada Atlantique",
    "Quebec/Québec",
  ];

  const  marketingToolkitsWithTabsData =
    marketingToolkitsCategories &&
    marketingToolkitsCategories?.sort((a, b) => a.localeCompare(b)).map((category, index) => {
      return {
        key: `tabMarketingToolkit-${index}`,
        panel: (
          <>
            {!marketingToolkitsInfo && <Loader />}
            {marketingToolkitsInfo !== null && (
              <div>
                <h2>
                  {locale === "fr"
                    ? category.split("/")[1]
                    : category.split("/")[0]}
                </h2>
                <Row cols={3}>
                  {marketingToolkitsInfo
                    ?.slice()
                    .sort((a, b) => ((a.startDate !== null) && -a.startDate.localeCompare(b.startDate)))
                    .filter((mt) => mt.category[0] === category)
                    .filter((marketingToolkit) =>
                      marketingToolkit?.regionName?.includes(selectedRegion)
                    )
                    .map(
                      (marketingToolkit) => {            
                        return (marketingToolkit?.marketingToolkitsImageAsset !== null 
                          && ((marketingToolkit?.startDate==null) || ((marketingToolkit?.startDate !==null) && (moment().isAfter(marketingToolkit?.startDate))))
                          && marketingToolkit?.expiryDate!==null && moment().isBefore(marketingToolkit?.expiryDate)
                        && (                          
                        <Cell key={marketingToolkit.displayTitle}>
                            <Card>
                              <Card.Image
                                className={styles.marketingToolkitsImage}
                                src={cloudinaryImage(
                                  marketingToolkit?.marketingToolkitsImageAsset?.[0]?.public_id,
                                  {
                                    width: 747,
                                  }
                                )}
                                alt="placeholder alt"
                              />
                              <Card.Body>
                                <p className={ styles.marketingToolkitsPostDate }>
                                  {marketingToolkit.startDate ? dayjs(marketingToolkit.startDate, "YYYY-MM-D").format(
                                    "MMM D, YYYY"
                                  ) : <br/>}
                                </p>
                                <Card.Header
                                  as="p"
                                  size="p"
                                  weight="bold"
                                  className={styles.marketingToolkitsCardHeading}
                                >
                                  {marketingToolkit.displayTitle}
                                </Card.Header>
                                {
                                  marketingToolkit.marketingToolkitsFileCollection?.items?.map((file) => {
                                      return (
                                        <Card.Link
                                          className={ styles.marketingToolkitsLink }
                                          target="_blank"
                                          to={
                                            !file
                                              ? ""
                                              : file.url
                                          }
                                        >
                                          <Icon
                                            className={styles.marketingToolkitsDownloadLink}
                                            name="download"
                                            aria-hidden="true"
                                          />
                                          {file?.url?.includes('/watch')
                                            ? RCL({ searchKey: "watch-now" })
                                            : marketingToolkit.marketingToolkitsFileCollection?.items.length > 1 
                                              ? file.description
                                              : RCL({ searchKey: "download-now" })
                                          }
                                          {marketingToolkit.marketingToolkitsFileCollection?.items.length > 1 ? <br/>:""} 
                                        </Card.Link>
                                      )
                                  })
                                }
                              </Card.Body>
                            </Card>
                          </Cell>
                        ))
                      }
                    )}
                </Row>
              </div>
            )}
          </>
        ),
        toggle: {
          iconComponent: <Icon className="custom-icon" name="children" />,
          label: {
            heading:
              locale === "fr" ? category.split("/")[1] : category.split("/")[0],
          },
        },
      };
    });

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/MarketingToolkits" },
        fr: { path: "/fr/MarketingToolkits" },
      });
    }
    getMarketingToolkitsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegionSelect = (e) => {
    setSelectedRegion(e.target.value);
  };

  useEffect(() => {
    if (config.isWestJetMode || (locale === "fr" && selectedRegion !== "Quebec/Québec")) {
      setSelectedRegion("Quebec/Québec");
    }
  }, [locale]);

  return (
    <div className={styles.marketingToolkits}>
      <Helmet>
        <title>{`Agent Portal - ${RCL({ searchKey: "MarketingToolkits_header" })}`}</title>
      </Helmet>
      {!config.isWestJetMode && marketingToolkitsInfo && (
         <Breadcrumb pageSlug={"marketingToolkits"} locale={locale} />
      )}
      <Section
        title={RCL({ searchKey: "MarketingToolkits_header" })}
        titleAlignment="left"
        titleUppercase={false}
      >
        <p className={styles.marketingToolkitsDesc}>
        {config.isWestJetMode ? <RCL searchKey="MarketingToolkitsVwq_Desc" /> : <RCL searchKey="MarketingToolkits_Desc" />}
        </p>
        <div className={styles.marketingToolkitsSelect}>
        {config.isWestJetMode ? 
          <div><RCL searchKey="regionfilter" /> { ": " + (locale === "fr" ? marketingToolkitRegions[0].split("/")[1] : marketingToolkitRegions[0].split("/")[0])}</div> : 
          (<>
            <label htmlFor="region">
              <RCL searchKey="regionfilter" />
            </label>
            
            <select
              id="regional"
              onChange={handleRegionSelect}
              value={selectedRegion}
            >
              {marketingToolkitRegions.map((region) => (
                <option value={region}>
                  {locale === "fr" ? region.split("/")[1] : region.split("/")[0]}
                </option>
              ))}
            </select>
          </>)}
        </div>
        {marketingToolkitsWithTabsData && (
          <Tabs
            activeKey={tabIndex}
            data={marketingToolkitsWithTabsData}
            tabIconLeftComponent={<Icon name="arrow-left" />}
            tabIconRightComponent={<Icon name="arrow-right" />}
            theme="generic"
            customTabWrapperClass="tabsWrapper"
            customPanelClass="tabsPanel"
            customActiveClass="tabsActive"
            className={styles.marketingToolkits}
          />
        )}
      </Section>
    </div>
  );
};

export default MarketingToolkits;
