export const headerQuery = `query allHeaderNavigation ($locale: String!, $brandCode:String!) {
  headerNavigationCollection(locale: $locale where: { brand: {brandCode:$brandCode} } limit: 1) {
    items {
      sys {
        id
      }
      logo
      logoLink {
        ... on Link {
          sys {
            id
          }
          linkText
          url
          target
        }      
      }
      displayLogoOnly
      ctaMessageLink {
        ... on Link {
          sys {
            id
          }
          linkText
          url
          target
        }
      }
      # Main Nav
      linksCollection {
        items {
          sys {
            id
          }
          linkText
          url
          target
        }
      }
      # Slide out Nav
      menuCollection(limit: 20) {
        items {
          sys {
            id
          }
          displayTitle
          menuItemsCollection(limit: 20) {
            items {
              ... on HeaderMenu {
                sys {
                  id
                }
                displayTitle
                menuItemsCollection(limit: 20) {
                  items {
                    ... on Link {
                      sys {
                        id
                      }
                      linkText
                      url
                      target
                    }
                  }
                }
              }
       
              ... on Link {
                sys {
                  id
                }
                linkText
                url
                target
              }
            }
          }
        }
      }
    }
  }
}
`;
