import { useState } from "react";
import { Heading, Modal } from "@sunwing/components";
import { Button as ButtonComponent } from "@sunwing/components/dist/Button";
import { RCL } from "../../components/RCL";
import { Link } from "react-router-dom";

const MyLoyaltyProgramCtas = ({ locale, starCardInfo, starCard }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  return (
    <>
      {starCardInfo && starCard}
      <div className="star-balance__cta-btns">
        <ButtonComponent
          outline
          size="lg"
          icon="arrow-right"
          iconPlacement="right"
          onClick={toggleModal}
        >
          <RCL searchKey="star_Howpointsrewarded" />
        </ButtonComponent>
        <ButtonComponent
          outline
          size="lg"
          to={`/${locale}/helpful-info#star-program`}
          icon="arrow-right"
          iconPlacement="right"
          linkComponentProps={{
            renderLink: (link) => {
              if (link.isInternal && link.to.indexOf("#") !== 0) {
                return (
                  <Link
                    to={link.to}
                    target={link.target}
                    className={link.className}
                  >
                    {link.children}
                  </Link>
                );
              }
              return (
                <a
                  href={link.to}
                  target={link.target}
                  className={link.className}
                >
                  {link.children}
                </a>
              );
            },
          }}
          target="_self"
        >
          <RCL searchKey="star_FAQ" />
        </ButtonComponent>
        <ButtonComponent
          outline
          size="lg"
          icon="arrow-right"
          iconPlacement="right"
          to={RCL({ searchKey: 'swg-terms-conditions' })}
          target="_blank"
        >
          <RCL searchKey="star_tc" />
        </ButtonComponent>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={toggleModal}
        labels={{ close: "Close", content: "" }}
        className="star-balance__modal"
        simple
        top={false}
      >
        <Modal.Content className="star-balance__modal-content">
          <Heading size="h2" as="p" titleAlignment="left">
            <RCL searchKey="howPointsAreAwarded" />
          </Heading>
          <p>
            <strong>
              <RCL searchKey="points-are-awarded-as-follows" />
            </strong>
          </p>
          <ul>
            <li>
              <RCL searchKey="all-itc-cruise-packages" />
            </li>
            <li>
              <RCL searchKey="all-air-only-1" />
            </li>
            <li>
              <RCL searchKey="all-air-only-2" />
            </li>
            <li>
              <RCL searchKey="hotel-only" />
            </li>
            <li>
              <RCL searchKey="car-only" />
            </li>
            <li>
              <RCL searchKey="worry-free-platinum" />
            </li>
          </ul>

          <p>
            <RCL searchKey="notes-ap" />
          </p>
          <ul>
          <li>
              <RCL searchKey="star-points-are-not-awarded-for-any-portions-booked-as-children" />
            </li>
            <li>
              <RCL searchKey="star-points-are-not-awarded-on-any-non-sunwing-airlines-flights-nor-any" />
            </li>
            <li>
              <RCL searchKey="when-booking-sunwing-e-packages-star-points-are-only-awarded-on-the-hotel" />
            </li>
            <li>
              <RCL searchKey="star-points-are-not-awarded-on-consumer-web-bookings" />
            </li>
            <li>
              <RCL searchKey="bonus-star-points-are-not-awarded-on-deviation-bookings" />
            </li>
            <li>
              <RCL searchKey="star-points-are-not-awarded-for-seat-selection-elite-plus-and-private" />
            </li>
          </ul>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default MyLoyaltyProgramCtas;
export { MyLoyaltyProgramCtas };
