import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Footer as FooterComponent } from "@sunwing/components";
import config from "../../app/config";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { useRouting } from "../../hooks";
import { Loader } from "../../components/Loader";
import { Select } from "antd";
import { VWQIcon } from "@sunwing/icons";
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import { useApp } from "../../hooks";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./FooterVwq.module.scss");
  } else {
    styles = await import("./Footer.module.scss");
  }
})();

const SelectOption = Select.Option;

const Footer = () => {
  
  const history = useHistory();
  const { locale, setLocale } = useContext(RCLContext);
    const { authState } = useOktaAuth();
  const { currentRoute } = useRouting();
  const [footerInfo, setFooterInfo] = useState(null);
  const [footerInfoFetchFailed, setFooterInfoFetchFailed] = useState(false);
  const location = useLocation();

  const filterLinkCategoryTitle = (includes, linkCatId) => {
    const linkCategoryTitle = includes.Entry.filter((ent) =>
      ent.sys.id.includes(linkCatId)
    )[0].fields.displayTitle;
    return linkCategoryTitle;
  };

  const filterLinkCategoryLinks = (includes, linkCatId) => {
    const linkCategoryEntries = includes.Entry.filter((ent) =>
      ent.sys.id.includes(linkCatId)
    )[0].fields.entries;
    const formattedFooterLinks = linkCategoryEntries?.map((entry, lindex) => {
      const filteredLink = includes.Entry.filter((ent) =>
        ent.sys.id.includes(entry.sys.id)
      )[0];

      let isInternalLink = false;
      if (!filteredLink.fields.url.includes("https://")) {
        isInternalLink = true;
      }
      return {
        id: `item-${lindex}`,
        title: filteredLink?.fields.linkText,
        url: filteredLink?.fields.url,
        target: filteredLink?.fields.target,
        isInternalLink,
      };
    });
    return (
      formattedFooterLinks ?? [
        { id: "item-0", title: "empty", url: "#", target: "_blank" },
      ]
    );
  };

  const filterLogoImages = (includes, logoLinkEntryId) => {
    const logoEntryFields = includes.Entry.filter((ent) =>
      ent.sys.id.includes(logoLinkEntryId)
    )[0].fields;
    return {
      url: logoEntryFields?.image != null ? logoEntryFields?.image[0]?.url : "",
      alt: logoEntryFields?.imageAltText,
    };
  };

  const filterLogoLinks = (includes, logoLinkEntryId) => {
    const logoEntryFields = includes.Entry.filter((ent) =>
      ent.sys.id.includes(logoLinkEntryId)
    )[0].fields;
    return {
      url: logoEntryFields?.url,
      target: logoEntryFields?.target,
    };
  };

  const getFooterInfoFromCfl = async () => {
    
    await fetch(
      `${config.resourceServer.contentfulApimOpenAgentUrl
      }&content_type=footer&locale=${locale === "fr" ? locale : "en-CA"}`
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {

        let footer = data.items.filter(f => 
          data.includes.Entry.filter(e => e.sys.id === f.fields.brand.sys.id)[0].fields.brandCode === config.brand.toUpperCase())[0];

        const formattedColumnInfo = footer.fields.linksList.map(
          (item, index) => {
            return {
              id: `${index}`,
              title: filterLinkCategoryTitle(data.includes, item.sys.id),
              links: filterLinkCategoryLinks(data.includes, item.sys.id),
            };
          }
        );

        const formattedLogoInfo = footer.fields.logoLinks.map(
          (item, index) => {
            return {
              id: `${index}`,
              ...filterLogoLinks(data.includes, item.sys.id),
              image: filterLogoImages(data.includes, item.sys.id),
            };
          }
        );

        const formattedFooterInfo = {
          columnInfo: formattedColumnInfo,
          logoInfo: formattedLogoInfo,
          copyright: footer.fields.footerCopyright,
        };
        setFooterInfo(formattedFooterInfo);
        setFooterInfoFetchFailed(false);
      })
      .catch((err) => {
        setFooterInfoFetchFailed(true);
        /* eslint-disable no-console */
        console.error(err);
      });
  };

  const handleChangeLanguage = (item) => {
    setLocale(item.lang);
    history.push(item.path + `?brand=${config.isWestJetMode ? "vwq" : "swg"}`);
  };

  const handleVwqLanguageChange = (value) => {
    history.push((value === "English" ? currentRoute["en"].path  : currentRoute["fr"].path) + `?brand=${config.isWestJetMode ? "vwq" : "swg"}`);
};

  const renderLinks = ({ id, title, url, target, isInternalLink }) => {
    if (isInternalLink) {
      return (
        <Link key={id} to={url}>
          {title}
        </Link>
      );
    }
    return (
      <a key={id} href={url} target={target}>
        {title}
      </a>
    );
  };

  const renderImageLinks = (link, { className, image }) => (
    <a className={styles.agentFooterLinks} href={link.url} target={link.target}>
      {image}
    </a>
  );

  const updateFooterLanguageSelectForWestJet = () => {
    if(config.isWestJetMode && styles){
      setTimeout(() => {
        const targetDiv = document.querySelector('.Footer_module_footerLang__d5e8ed8d');
        var iDiv = document.getElementById('footerLanguageSelect');
        if(targetDiv){
          targetDiv.replaceChild(iDiv, targetDiv.childNodes[0]);
        }
      }, 2000);
    }else {
      setTimeout(() => {
        if(document.getElementById('footerLanguageSelect'))
          document.getElementById('footerLanguageSelect').hidden = true;
      }, 2000);
    }
  }
  // fetch footer information
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      getFooterInfoFromCfl();
      updateFooterLanguageSelectForWestJet();
    }
  }, [authState, locale, styles]);

  return (
    <>
      {styles && (
        <>
        {!footerInfo && !footerInfoFetchFailed && <Loader />}
        {footerInfo && (
          <FooterComponent
            className={`${ styles.vwq } page__footer`}
            columns={footerInfo.columnInfo}
            renderLinks={renderLinks}
            copyrightText={footerInfo.copyright}
            corps={footerInfo.logoInfo}
            renderImageLinks={renderImageLinks}
            handleChangeLanguage={handleChangeLanguage}
            lang={locale}
            languagePages={[
              {
                id: "en",
                label: "English",
                lang: "en",
                path: currentRoute["en"].path,
              },
              {
                id: "fr",
                label: "Français",
                lang: "fr",
                path: currentRoute["fr"].path,
              },
            ]}
          />
        )}
        <div id='footerLanguageSelect'>
          <div className={styles.languageSelectContainer}>
            <Select
              defaultValue={location?.pathname?.includes("/fr/") ? "Français" : "English"}
              onChange={handleVwqLanguageChange}
            >
              <SelectOption value="English"  key="English">
                <div className={styles.languageSelectContainer}>
                  <div className={styles.languageSelectItem}>
                    <span>English</span> 
                  </div>
                  <VWQIcon name="globe" className={styles.languageSelectIcon} />
                </div>
              </SelectOption>
              <SelectOption value="Français" key="Français">
                <div className={styles.languageSelectContainer}>
                  <div className={styles.languageSelectItem}>
                      <span>Français</span>  
                  </div>
                  <VWQIcon name="globe" className={styles.languageSelectIcon} />
                </div>
              </SelectOption>
            </Select>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default Footer;
