import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { Row, Cell, Card, Icon, Section } from "@sunwing/components";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { RCL } from "../../components/RCL";
import { useRouting } from "../../hooks";
import { cloudinaryImage } from "../../utils/cloudinary";
import { Loader } from "../../components/Loader";
import { Breadcrumb } from "../../components/Breadcrumb";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";
import { VWQIcon } from "@sunwing/icons";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./webinarVwq.module.scss");
  } else {
    styles = await import("./webinar.module.scss");
  }
})();

const Webinar = () => {
  const { locale } = useContext(RCLContext);
  const { setCurrentRoute } = useRouting();
  const { oktaAuth } = useOktaAuth();
  const [webinars, setWebinars] = useState(null);
  const [searchString, setSearchString] = useState('');

  const getWebinars = async () => {
    const query = `
      query webinarsData($locale: String, $skip:Int, $currentDate:DateTime, $brandCode:String!) {
        webinarsCollection(locale: $locale, limit:100, skip:$skip, where: { webinarExpiryDate_gte:$currentDate, brand: {brandCode:$brandCode} }) {
          items {
            webinarTitle
            webinarPromotionType
            webinarFlyerFileCollection {
              items {
                url
                size
                description
              }
            }
            webinarImageAsset
            webinarYouTubeUrl
            webinarDescription
            webinarEffectiveDate
            webinarExpiryDate
          }
        }
      }`;

    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: query,
        variables: { 
          locale: locale === "fr" ? locale : "en-CA", 
          "skip":0,
          "currentDate":(new Date()).toISOString().substring(0, 10),
          brandCode:config.brand ? config.brand.toUpperCase() : 'SWG'
        },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }else{
        setWebinars(data?.webinarsCollection?.items);
        }
      });
  };

  const webinarsSorted = webinars
    ?.slice()
    .filter(webinar => webinar.webinarTitle == null || webinar.webinarTitle?.toLowerCase().includes(`${searchString}`))
    .sort(
      (a, b) => -a.webinarEffectiveDate.localeCompare(b.webinarEffectiveDate)
    );

  useEffect(() => {
    if (setCurrentRoute) {
      setCurrentRoute({
        en: { path: "/en/webinar" },
        fr: { path: "/fr/webinar" },
      });
    };
    getWebinars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.webinars}>
      <Helmet>
        <title>
          {`Agent Portal - ${RCL({ searchKey: "Webinars_Header" })}`}
        </title>
      </Helmet>
      {!config.isWestJetMode && webinars && (
        <Breadcrumb pageSlug={"webinar"} locale={locale} />
      )}
      {config.isWestJetMode && 
        (<>
          <div className={styles.webinarsSearchBar}>
              <VWQIcon name="search" className={styles.webinarsSearchIcon}/>
              <input placeholder={RCL({ searchKey: "search" }) } 
                    value={searchString} 
                    className={styles.webinarsSearchInput}
                    onChange={(e) => setSearchString(e.target.value.toLowerCase())}/>
          </div>

            </>
        )
      }
      
      <Section
        title={RCL({ searchKey: "Webinars_Header" })}
        titleSize="h1"
        titleAlignment="left"        
      >
        <p className={  styles.webinarsDesc}>
        {config.isWestJetMode ? <RCL searchKey="WebinarsVwq_Desc" /> : <RCL searchKey="Webinars_Desc" />}
        </p>
        {!config.isWestJetMode && 
          (<>
            <div className={styles.webinarsSearchBar}>
              <input placeholder={ RCL({ searchKey: "search-webinars" }) } 
                    value={searchString} 
                    onChange={(e) => setSearchString(e.target.value.toLowerCase())}/>
            </div>
            <br></br>
            <br></br>
          </>)
        }
        {!webinars && <Loader />}
        {webinars && !!webinars.length && (
          <div>
            <Row cols={3}>
              {webinarsSorted.map(
                (webinar) =>
                  webinar?.webinarImageAsset !== null && (
                    <Cell key={webinar.webinarTitle}>
                      <Card>
                        <Card.Image
                          className={styles.webinarsImage}
                          src={cloudinaryImage(
                            webinar?.webinarImageAsset?.[0]?.public_id,
                            {
                              width: 747,
                            }
                          )}
                          alt="placeholder alt"
                        />
                        <Card.Body>
                          <p className={styles.webinarsPostDate}>
                            {dayjs(
                              webinar.webinarEffectiveDate,
                              "YYYY-MM-D"
                            ).format("MMM D, YYYY")}
                          </p>
                          <Card.Header
                            className={styles.webinarsHeading}
                            as="h5"
                          >
                            {webinar.webinarTitle.length > 50 ? 
                              webinar.webinarTitle.substring(0, 50) + " ..." : 
                              webinar.webinarTitle}
                          </Card.Header>
                          {webinar.webinarYouTubeUrl !== null && (
                            <Card.Link
                              className={styles.webinarsVideoLink}
                              target="_blank"
                              to={webinar.webinarYouTubeUrl}
                            >
                              {config.isWestJetMode ? 
                                <>
                                  {RCL({ searchKey: "AgentTools_Watch" })}
                                  <VWQIcon
                                  className={styles.webinarsPlayLink}
                                  name="play-micro"
                                  aria-hidden="true"/>
                                </> :
                                <>
                                  <Icon
                                    className={styles.webinarsPlayLink}
                                    name="video--o"
                                    aria-hidden="true"
                                  />
                                  {RCL({ searchKey: "AgentTools_Watch" })}
                                </>
                              }
                            </Card.Link>
                          )}
                          {webinar.webinarFlyerFileCollection.items[0] !==null
                           && webinar.webinarFlyerFileCollection.items[0]
                           && (
                            <Card.Link
                              className={styles.webinarsLink}
                              target="_blank"
                              to={
                                !webinar.webinarFlyerFileCollection.items[0]
                                  ? ""
                                  : webinar.webinarFlyerFileCollection.items[0]
                                      .url
                              }
                            >
                               {config.isWestJetMode ? 
                                <>
                                  {RCL({ searchKey: "AgentTools_Download" })}
                                  <VWQIcon
                                    className={styles.webinarsPlayLink}
                                    name="download-micro"
                                    aria-hidden="true"
                                  />
                                </>
                                : 
                                <>
                                  <Icon
                                    className={styles.webinarsDownloadLink}
                                    name="download"
                                    aria-hidden="true"
                                  />
                                 {RCL({ searchKey: "AgentTools_Download" })}
                                </>
                              }                           
                            </Card.Link>
                          )}
                        </Card.Body>
                      </Card>
                    </Cell>
                  )
              )}
            </Row>
          </div>
        )}
      </Section>
    </div>
  );
};

export default Webinar;
