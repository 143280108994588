import React, {  useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useOktaAuth } from "@okta/okta-react";
import { useApp, useRouting } from "./hooks";
import { Loader } from "./components/Loader";
import { FluidSection } from "./FluidSection";
import { Breadcrumb } from "./components/Breadcrumb";
import { useSectionItems } from "../src/hooks/contentful/useSectionItems";
import { useTotalSectionCount } from "../src/hooks/contentful/useTotalSectionsCount";
import styles from "./components/BrandSelection/BrandSelection.module.scss";
import config from "../src/app/config";
import { RCL } from "./components/RCL";

const PageSection = ({ userInfo, contentfulId, locale, sectionIndex }) => {

  const { items } = useSectionItems(contentfulId, sectionIndex, locale);

  if(items)
    return <FluidSection sections={items} userInfo={userInfo} locale={locale} />
  else
    return <Loader />;
};

const Page = ({ contentfulId, locale, route }) => {
  const { authState } = useOktaAuth();
  const { setPageContentfulId, setCurrentRoute } = useRouting();
  const { userInfo } = useApp();

  const { pageTabHeader, pageSlug, parent, totalSectionsCount } = useTotalSectionCount(contentfulId, locale);

  const handleVacationForEveryGroupLinks = () => {
    setTimeout(() => {
      let leftSectionLinks = document.querySelectorAll('section[id*="a"] > div:nth-child(1) > div > div:nth-child(1) > div:nth-child(2) > a.Button_module_btn_Primary__e945c4a6');
      
      leftSectionLinks.forEach((link) => {
        link.style= "color: #017F7C !important; background-color: #FFFFFF !important; font-weight: 600 !important; line-height: 21px !important;";
      });    
    }, 500);
  }

  useEffect(() => {
    if (setPageContentfulId) {
      setPageContentfulId(contentfulId);
      if (setCurrentRoute) {
        setCurrentRoute({
          en: { path: route["en"].path },
          fr: { path: route["fr"].path },
        });
      }
    }

    if(!window.location.href.includes('/login')){
      const params = new URL(window.location.href).searchParams;
      const brand = params.get('brand');

      if(brand && brand !== config.brand){
        window.location.reload();
      }
    }

    //add brand selection to the home page
    if(window.location.href.includes('/home') && !window.location.search.includes('?brand')) {
      document.querySelector("#brandModal").showModal();
    }else{
      document.querySelector("#brandModal").close();
    }

    if(window.location.href.includes('/home')) {

      //home page. set top banner width to its original value
      setTimeout(() => {

        let topBanner;

        topBanner = document.querySelector("#root > div.ui.text.container.page__container > section:nth-child(3) > div > div > div > div > div > div > img");

        if(!topBanner) {
          topBanner = document.querySelector("#root > div.ui.text.container.page__container > section:nth-child(2) > div > div > div > div > div > div > img");
        }

        if(topBanner){   
          topBanner.src = topBanner?.src.replace(",w_747", "");
        };

      }, 2000);
    }

    //replace meet-the-team "+/-" with "^/v"
    if(authState && authState.isAuthenticated && config.isWestJetMode && window.location.href.includes('/meet-the-team')) {
      setTimeout(() => {
        var nodes = document.querySelectorAll(`img.Img_module_responsive__e0e919b3.Profile_module_profile__imgWrapper__606e34c9`);

        if(nodes && nodes?.length > 0){
          nodes.forEach(node => { 
            node.parentNode.childNodes[1].classList.replace("swg-icon-plus--n", "swg-icon-arrow-down");

            node.parentNode.addEventListener("click", (e) => {
              setTimeout(() => { 
                nodes.forEach(node => { 
                  node.parentNode.childNodes[1].classList.replace("swg-icon-plus--n", "swg-icon-arrow-down");
                  node.parentNode.childNodes[1].classList.replace("swg-icon-minus--n", "swg-icon-arrow-up");
                });
              }, 50);
            }, false)
          });
          sessionStorage.setItem("meetTheTeamReloadTimes", 0);
        }else {       
          let reloadTimes = parseInt(sessionStorage.getItem("meetTheTeamReloadTimes")??0);
          if(reloadTimes < 3){
            sessionStorage.setItem("meetTheTeamReloadTimes", reloadTimes + 1);
            window.location.reload();
          }else {
            console.log("done try", reloadTimes, parseInt(sessionStorage.getItem("meetTheTeamReloadTimes")??1) * 2000);
          }
        }

      }, parseInt(sessionStorage.getItem("meetTheTeamReloadTimes")??1) * 2000);
    };
    
    //update group for every one detail 
    if(authState && authState.isAuthenticated && config.isWestJetMode && window.location.href.includes('/vacations-for-every-group-detail')) {
   
      setTimeout(() => {

        handleVacationForEveryGroupLinks();
        
        var targetDivs = document.getElementsByClassName('StarRating_module_rating__01c0005c');
        
        for (let targetDiv of targetDivs) {
          
          let rating = targetDiv?.getAttribute("rating");
          let iDiv = document.createElement('div');
          iDiv.style.color = 'black';
          iDiv.style.paddingLeft = '0.3rem';
          iDiv.style.paddingTop = '0.15rem';
          iDiv.textContent = rating;
          targetDiv.appendChild(iDiv);

        }

      }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrandSelected = (brand) => {
    let lang =  locale.includes("en") ? "en" : "fr";
    window.location.href = window.location.origin + `/${lang}/home?brand=${brand}`;
  }

  if (!authState) {
    return <Loader />;
  }
  
  return (
    <>
      <Helmet>
        <title>{pageTabHeader}</title>
      </Helmet>
      {!config.isWestJetMode && <Breadcrumb pageSlug={pageSlug} locale={locale} parent={parent} />}
      {[...new Array(totalSectionsCount)].map((_, index) => (
        <PageSection
          key={index}
          userInfo={userInfo}
          contentfulId={contentfulId}
          locale={locale}
          sectionIndex={index}
        />
      ))}
      <dialog id="brandModal" className={styles.dialog}>
        <p className={styles.dialogTitle}>{RCL({ searchKey: "welcome-agents"})?.toString().replace("{{firstName}}",  userInfo?.given_name ?? "")}</p>
        <p className={styles.dialogContent}><RCL searchKey="lets-get-started-which-travel-agent-portal" /></p>
        <p className={styles.dialogContent}><RCL searchKey="please-notes-westjet-vacations-quebec-solely-sells"/></p>
        <button id="sunwingBtn" className={styles.dialogButton} onClick={() => handleBrandSelected('swg')}><RCL searchKey="sunwing-vacations" /></button>
        <button id="westJetBtn" className={styles.dialogButton} onClick={() => handleBrandSelected('vwq')}><RCL searchKey="westjet-vacations-quebec" /></button>
      </dialog>
    </>
  );
};

export default Page;
export { Page };
