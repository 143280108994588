import { useEffect, useState } from "react";
import config from "../../app/config";
import { useOktaAuth } from "@okta/okta-react";
import { GET_PAGE_SECTIONS_COUNT } from "../../query/page.query";

export const useTotalSectionCount = (contentfulId, locale) => {
  const { oktaAuth } = useOktaAuth();
  const [pageTabHeader, setPageTabHeader] = useState();
  const [slug, setSlug] = useState();
  const [parent, setParent] = useState();
  const [totalSectionsCount, setTotalSectionsCount] = useState(0);

  const getTotalSectionsCount = async (contentfulId, locale) => {
  
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: GET_PAGE_SECTIONS_COUNT,
        variables: { contentfulId : contentfulId, locale: locale },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setPageTabHeader(data?.page?.seo.title);
        setSlug(data?.page?.slug);
        setParent(data?.page?.parent);
        setTotalSectionsCount(data?.page?.sectionsCollection?.total);
      });
  };

  useEffect(() => {
    getTotalSectionsCount(contentfulId, locale);
  }, [contentfulId, locale]);

  return { pageTabHeader, slug, parent, totalSectionsCount };
};
