/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Menu } from "semantic-ui-react";
import { Header as HeaderComponent, Icon } from "@sunwing/components";
import config from "../../app/config";
import { headerQuery } from "./Header.fragment";
import { RCLContext } from "../RCL/RCLProvider";
import { RCL } from "../RCL";
import { useRouting, useApp } from "../../hooks";
import { replaceRedirectUrl } from "../../utils/replaceRedirectUrl";
import { RouterLink } from "../RouterLink";
import { VWQIcon } from "@sunwing/icons";
import { messaging, getFingerprint, onMessageListener} from "../WebPush/firebase";
import WebPushPopup from "../WebPush/WebPushPopup";
import { getToken } from "firebase/messaging";
import InboxBell  from "../WebPush/InboxBell";
import alarmBeep from './beep.mp3';
import { Modal } from "antd";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./HeaderVwq.module.scss");
  } else {
    styles = await import("./Header.module.scss");
  }
})();

const Header = ({ setCorsErrorModalOpen }) => {
  const history = useHistory();
  const { authState, oktaAuth, authService } = useOktaAuth();
  const { locale, lang, setLocale } = useContext(RCLContext);
  const { currentRoute } = useRouting();
  const { userInfo, MBPRedirectUrl, IGQRedirectUrl, inboxMessages } = useApp();

  const [headerData, setHeaderData] = useState(null);
  const [showBrandDropDown, setShowBrandDropDown] = useState(false);
  const [showWestJetBar, setShowWestJetBar] = useState(true);
  const [notification, setNotification] = useState({title: '', body: '', imageUrl:'', redirectUrl:'', campaignCode:''});
  const [showWebPushPopup, setShowWebPushPopup] = useState(false);
  const [enableWebPush, setEnableWebPush] = useState(false);
  const [showSafariConfirm, setShowSafariConfirm] = useState(false);
  const alarm = new Audio(alarmBeep);
  const closeStr = RCL({ searchKey: "close-agent" });

  const getHeader = async (token) => {

    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: headerQuery,
        variables: { locale: locale === "fr" ? locale : "en-CA", brandCode:config.brand ? config.brand.toUpperCase() : 'SWG' },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setHeaderData(data?.headerNavigationCollection?.items[0]);
      });
  };

  if (locale === undefined) {
    throw new Error("locale is undefined.");
  }

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";

  const logout = async () => {
    try {
      const accessToken = oktaAuth.getAccessToken();
      oktaAuth.revokeAccessToken(accessToken);
      await oktaAuth.closeSession();
      await authService.signOut();
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  const moveNotifToLast = () => {
    if (window.innerWidth <= 600) {
      const elementToMove = document.querySelector('.Header_module_alertBtnInfo__2c1d44cd');
      const targetContainer = document.querySelector('.Header_module_headerWrapper__2c1d44cd');

      if (elementToMove && targetContainer) {
        targetContainer.prepend(elementToMove);
      }
    }
  }

  const getCustomerInfo = async (token) => {
    await fetch(`${config.resourceServer.customerApiUrl}/v2/customer`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        handleWebPush(data);
      });
  };

  // fetch messages
  useEffect(() => {
    moveNotifToLast();
    
    if (authState && authState.isAuthenticated) {

      registerFirebaseServiceWorker();

      const accessToken = oktaAuth.getAccessToken();
      getHeader(accessToken);
      getCustomerInfo(accessToken); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth, userInfo, locale]);

  if (!authState) {
    return null;
  }

  const registerFirebaseServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function(registration) {
        })
        .catch(function(err) {
          console.log('Service Worker registration failed:', err);
        });
    }
  };

  const handleWebPush = (customerInfo) => {

    if (!('serviceWorker' in navigator) || !('PushManager' in window) || !window.Notification) {
      console.log('This browser does not support push notifications.');
        return;
    }

    if(userInfo && customerInfo){   
      //Inbox Messaging
      if(!customerInfo.contactPreferences.isInboxSubscribed){
        registerInboxMessaging();       
      }
  
      let currentPermission = Notification.permission;

      //Push Notification
      if(currentPermission === 'denied'){
        if (localStorage.getItem("web-push-storage") !== null) {
          localStorage.removeItem('web-push-storage');
        } 
          console.log("User denied permission for notification"); 
        } 
      else if(currentPermission !== 'granted'){

        if (localStorage.getItem("web-push-storage") !== null) {
          localStorage.removeItem('web-push-storage');
        } 

          Notification.requestPermission().then((permission) => {  
          if (permission === 'granted') {
            subscribeWebPush();
          }else {
            if(getBrowserName() === 'Safari'){
              setShowSafariConfirm(true);
            }
          }
        });    
      }               
      else{
        subscribeWebPush();
      }

      //post user id to firebase service work.
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          userId: customerInfo?.universalId,
          email: userInfo.email,
          close: closeStr,
          lang: lang
        });
      }

      //Segment user ID
      window.analytics.identify(customerInfo?.universalId, { email: userInfo?.email});
    }
  }

  const subscribeWebPush = async() => {

    let deviceId = userInfo?.agentGlobalId + getFingerprint();
    let webPushStorage = localStorage.getItem('web-push-storage');

    if(webPushStorage !== null){
      let createAt = new Date(JSON.parse(webPushStorage).createAt);     
      let diffDays = Math.ceil((new Date() - createAt)/(1000 * 60 * 60 * 24));

      if(diffDays > 60){
        getFirebaseToken('PUT',deviceId, 3);
      } else{

        //if the browser has been registered for another agent email, 
        //then need to register for the current agent with the agent global ID.
        if((JSON.parse(webPushStorage).deviceID === deviceId) && (JSON.parse(webPushStorage).hostname === window.location.hostname) )
        {
          //makesure the saved token is still valid.
          validateToken(JSON.parse(webPushStorage).token, deviceId);
        }else { 
          getFirebaseToken('POST', deviceId, 3);
        }
      }
    }else{
      getFirebaseToken('POST', deviceId, 3);
    }
  }

  const getFirebaseToken = (method, deviceId, retry) => {
    //Generate Token
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATES})
    .then((currentToken) => {
        if (currentToken) {
          registerWebPush(currentToken, deviceId, method); 
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
    })
    .catch((err) => {
      if (retry > 0)
      {
        setTimeout(getFirebaseToken(method, deviceId, retry - 1), 2000);
      }else{
        console.log('An error occurred while retrieving token. ', err);
      }
    });
  }

 const registerWebPush = (token, deviceId, method) => {
    const registerData = {
      "type": "Browser",
      "platformType": "Andriod",
      "isSubscribed": true,
      "id": deviceId,
      "token": token,
      "name": getBrowserName(),
      "version": "1.0.0"
    };

    fetch(`${config.resourceServer.customerApiUrl}/notifications/push/register`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify(registerData),
    })
    .then((res) => { 
      localStorage.setItem("web-push-storage", JSON.stringify({token:token, deviceID: deviceId, hostname:window.location.hostname, createAt: (new Date()).toString()}));
      setEnableWebPush(true);
    })
    .catch((error) => {
      setEnableWebPush(false);
      console.log(error);
    });
  };

  const registerInboxMessaging = () => {
    const registerData = {
      "type": "Browser",
      "platformType": 'Andriod',
      "isSubscribed": true,
      "id": userInfo?.agentGlobalId,
      "token": "agentportal",
      "name": getBrowserName(),
      "version": "1.0.0"
    };

    fetch(`${config.resourceServer.customerApiUrl}/notifications/inbox/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify(registerData),
    })
    .then((res) => { 
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const validateToken = (savedToken, deviceId) => {
    //Generate Token
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATES})
      .then((currentToken) => {
        if(savedToken === currentToken){
          setEnableWebPush(true);
        }else{
          registerWebPush(currentToken, deviceId, 'PUT');
        }
      })
      .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
      });
  }

  const getBrowserName = () => {
    let browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox'
    } else {
      browser = 'unknown'
    }
      return browser;
  }

  const handleChangeLanguage = (item) => {
    setLocale(item.lang);
    history.push(item.path);
  };

  const handleSafariConfirmOk = () => {               
    Notification.requestPermission().then((permission) => {  
      if (permission === 'granted') {
        subscribeWebPush();
      }
    })
    setShowSafariConfirm(false);
  }

  const handleSafariConfirmClose = () => {
    setShowSafariConfirm(false);
  }

  const parsedHeaderData = {
    logo: <img src={headerData?.logo[0]?.url} alt="sunwing logo" />,
    links: headerData?.linksCollection?.items.map((link) => ({
      id: link.sys.id,
      linkText: link.linkText,
      target: link.target,
      url: replaceRedirectUrl(link.url, MBPRedirectUrl, IGQRedirectUrl),
    })),
    menuLinks: headerData?.menuCollection?.items.map((menu) => ({
      displayTitle: menu.displayTitle,
      id: menu.sys.id,
      links: menu.menuItemsCollection?.items.map((menuItem) => {
        if (menuItem.linkText) {
          return {
            id: menuItem.sys.id,
            linkText: menuItem.linkText,
            url: replaceRedirectUrl(menuItem.url, MBPRedirectUrl, IGQRedirectUrl),
            target: menuItem.target,
          };
        }

        if (menuItem.menuItemsCollection?.items) {
          return {
            id: menuItem.sys.id,
            displayTitle: menuItem?.displayTitle,
            links: menuItem?.menuItemsCollection?.items.map((item) => ({
              id: item.sys.id,
              linkText: item.linkText,
              url: replaceRedirectUrl(item.url, MBPRedirectUrl, IGQRedirectUrl),
              target: item.target,
            })),
          };
        }
      }),
    })), 
  };
 
  onMessageListener().then(payload => {
    if(enableWebPush){
      setShowWebPushPopup(true);

      alarm.play();
      
      if(payload.notification){
        //firebase test app. can be removed
        setNotification({title: payload.notification.title??"", body: payload.notification.body??"", imageUrl:payload.notification.image??'', redirectUrl:'', campaignCode:"firebase test app no campaign Code"});
      }else{
         setNotification({
          title: payload.data.title??"", 
          body: payload.data.alert??"", 
          imageUrl:payload.data.image??'', 
          redirectUrl: payload.data._od?payload.data._od:payload.data.redirectTo, 
          campaignCode: payload.data.campaign??"no campaign code"
        });
      }

      setTimeout(() => { 
        handleWebPushClose();
      }, 3600000);
    }
  }).catch(err => {setShowWebPushPopup(false); setNotification({title: '', body: '', imageUrl:'', redirectUrl:'', campaignCode:''}); console.log('failed: ', err);});


  const handleWebPushClose = () => {
    setShowWebPushPopup(false);
    window.location.reload();
  }

  const handleBrandChange = (brand) => {
    window.location.href = window.location.origin + `/${lang}/home?brand=${brand}`;
  };

  const handleAgentClicked = () => {
    setShowBrandDropDown(!showBrandDropDown);
  };

  const handleWestJetBarCloseBtn = () => {
    setShowWestJetBar(false);
  }

  const nameInitials = (inputString) => {
    const words = inputString.split(" ");

    let first = "";
    let second = "";
    first = words[0]?.substring(0, 1);
    second = words[1]?.substring(0, 1);
    const result = first + second;

    return result?.toUpperCase();
  }

  const helloBrand = RCL({ searchKey: "hello-agent" });
  
  let logoSrc;
  if (config.isWestJetMode && window.innerWidth <= 600) {
    logoSrc = '/leaf.png';
  } else if (config.isWestJetMode) {
    logoSrc =  locale === 'en' ? "https://assets.sunwingtravelgroup.com/image/upload/v1711986496/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-white-en.svg":
    "https://assets.sunwingtravelgroup.com/image/upload/v1711986497/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-white-fr.svg";
  } else {
    logoSrc = headerData?.logo[0]?.url ?? 'http://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg';
  }
  
  return (
      <>
      {styles && 
        (<>
          {authState && authState.isAuthenticated === true && userInfo &&
            (<div className={styles.brandTab}>
              <div className={styles.brandLinkContainer}>
                <a onClick={() => { handleBrandChange('swg') }}>
                  <img
                    src={
                      "https://assets.sunwingtravelgroup.com/image/upload/v1614627906/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo.svg" 
                    }
                    alt="sunwing logo"
                    className={styles.brandTabSunwingImage}
                  />
                </a>
                <a onClick={() => { handleBrandChange('vwq') }}>
                  <img
                    src={
                       locale === 'en' ? "https://assets.sunwingtravelgroup.com/image/upload/v1711986496/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-white-en.svg":
                       "https://assets.sunwingtravelgroup.com/image/upload/v1711986497/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-white-fr.svg"
                    }
                    alt="sunwing logo"
                    className={styles.brandTabVWQImage}
                  />
                </a>
              </div>
              {
                <div className={styles.headerRight}>
                  <span className={styles.helloBrand} >
                    {config.isWestJetMode ? helloBrand?.replace("brand", "Westjet") : helloBrand?.replace("brand", "Sunwing")}
                  </span>
                  <div className={styles.headerProfileIcon} onClick={() => handleAgentClicked()}>{nameInitials(userInfo.name)}
                    {showBrandDropDown && (
                      <div className={styles.dropdownPop}>
                        <Menu.Item id="profile-button" className={styles.oktaTooltipAccount}>
                          <RouterLink to={`/${lang}/myaccount`}>
                            <RCL searchKey="Profile_myaccount" />
                          </RouterLink>
                        </Menu.Item>
                        <Menu.Item
                          id="logout-button"
                          onClick={logout}
                          className={styles.oktaTooltipSignOut}>
                          <Link to={`/${lang}/login`}>
                            <RCL searchKey="Profile_signout" />
                          </Link>
                        </Menu.Item>
                      </div>)}
                  </div>
                </div>}
            </div>)}
          <HeaderComponent
            classNames={{
              header: `${authState && authState.isAuthenticated === true ? styles.vwq : styles.logoutHeader} 
                  page__header` }}
            alerts={{
              count: 1,
              label: <RCL searchKey="travel-advisories" />,
              link:
                config.isWestJetMode ? 
                  (locale === "fr" ? 
                    "https://www.vacanceswestjetquebec.com/fr/avis-aux-voyageurs" : 
                    "https://www.VacancesWestJetQuebec.com/en/travel-alerts") : 
                  (locale === "fr" ? 
                    "https://www.sunwing.ca/fr/travel-alertss" : 
                    "https://www.sunwing.ca/en/travel-alerts"),
              target: "_blank",
            }}
            logoOnly={!authState.isAuthenticated}
            currentLanguage={locale}
            handleChangeLanguage={handleChangeLanguage}
            languagePages={[
              {
                id: "en",
                label: "English",
                lang: "en",
                path: currentRoute["en"].path + `?brand=${config.isWestJetMode ? "vwq" : "swg"}`,
              },
              {
                id: "fr",
                label: "Français",
                lang: "fr",
                path: currentRoute["fr"].path + `?brand=${config.isWestJetMode ? "vwq" : "swg"}`,
              },
            ]}
            logo={(authState && authState.isAuthenticated === true) ?
              (<RouterLink to={`/${lang}/home`}>
                <img
                  src={logoSrc}
                  alt="sunwing logo"
                />
              </RouterLink>) :
              (<div className={styles.loginHeaderLogos}>
                <img
                  src={
                    "https://assets.sunwingtravelgroup.com/image/upload/v1614627906/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo.svg"
                  }
                  className={styles.brandTabSunwingImage}
                  alt="sunwing logo" />
                <div className={styles.logoSeparator}></div>
                <img
                  src={
                    headerData?.logo[0]?.url ??
                    (locale === 'en' ? "http://assets.sunwingtravelgroup.com/image/upload/f_svg/v1711811546/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-en.svg"
                    : "http://assets.sunwingtravelgroup.com/image/upload/f_svg/v1711811546/WestJet/DIGITAL/VWQ/LOGOS/vacances-westjet-quebec-logo-fr.svg")
                  }
                  className={styles.brandTabSunwingImage}
                  alt="WestJet logo" />
              </div>)
            }
            links={parsedHeaderData?.links}
            menuLinks={parsedHeaderData?.menuLinks}
            renderLink={(link, { classNames }) => {
              if (link.target !== "_blank" && !link.url.includes("http")) {
                return (
                  <Link to={link.url} className={classNames?.link}>
                    {link.linkText}
                  </Link>
                );
              } else {
                return (
                  <a
                    href={link.url}
                    target={link.target}
                    className={classNames?.link}
                  >
                    {link.linkText}
                  </a>
                );
              }
            }}
            renderMenuLink={(link, { classNames }) => {
              if (link.target !== "_blank" && !link.url.includes("http")) {
                return (
                  <Link to={link.url} className={classNames?.link}>
                    {link.linkText}{" "}
                    {link.target === "_blank" && (
                      config.isWestJetMode ?  <VWQIcon name="external-micro" className={styles.externalIcon} /> : <Icon name="external-link" className={styles.externalIcon} /> 
                    )}
                  </Link>
                );
              } else {
                return (
                  <a
                    href={link.url}
                    target={link.target}
                    className={classNames?.link}
                  >
                    {link.linkText}{" "}
                    {link.target === "_blank" && (
                      config.isWestJetMode ?  <VWQIcon name="external-micro" className={styles.externalIcon} /> : <Icon name="external-link" className={styles.externalIcon} />
                    )}
                  </a>
                );
              }
            }}
            showGateway={false}
          >
          {authState.isAuthenticated && (
              <div slot="secondary-nav--start">
                  <InboxBell lang={lang} inboxMessages={inboxMessages} email={userInfo?.email}></InboxBell>
              </div>
            )
          }
          </HeaderComponent>
          {showWestJetBar && window.location.href.includes('/home') && config.isWestJetMode &&
            (<div className={styles.westJetBarContainer}>
              <div className={styles.westJetBar}>
                <div className={styles.westJetBarContent}>
                  <img src='/info.png' alt="info" className={styles.westJetBarInfoIcon} /> 
                  <RCL searchKey="please-notes-westjet-vacations-quebec-solely-sells" />
                </div>
                <a onClick={() => { handleWestJetBarCloseBtn() }}><VWQIcon name="close" className={styles.westJetBarCloseBtn}></VWQIcon></a>
              </div>
            </div>)}
          {showWebPushPopup && (<WebPushPopup notification={notification} handleWebPushClose={handleWebPushClose} email={userInfo?.email}/>)}
          <Modal             
              visible={showSafariConfirm}
              onOk={handleSafariConfirmOk}
              onCancel={handleSafariConfirmClose}
              okText={'Allow'}
              cancelText={"Don't Allow"}     
              closable={false}     
              bodyStyle={{ marginTop: "20%" }}
          >
            <p> <RCL searchKey="agent-portal-would-like-to-send-you-notifications" /></p>
          </Modal>
        </>)
      }
    </>
  );
};
export default Header;
export { Header };
