/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../app/config";
import { RCLContext } from "../../components/RCL/RCLProvider";
import { Heading, Tabs, Icon } from "@sunwing/components";
import { StarBalance } from "../../components/Star/Balance";
import { ActivateStarCard } from "../../components/Star/ActivateStarCard";
import { StarStats } from "../../components/Star/Balance/StarStats";
import { StarCard } from "../../components/StarCard";
import { Row, Cell } from "@sunwing/components";
import { Img } from "@sunwing/shared-components";
import { RCL } from "../../components/RCL";
import { ParagraphConnector } from "../../components/Paragraph";
import { Loader } from "../../components/Loader";
import MyLoyaltyProgramCtas from "./MyLoyaltyProgramCtas";
import PlatinumClub from "./PlatinumClub";
import PlatinumClubCtas from "./PlatinumClubCtas";

let styles;

(async () => {
  if (config.isWestJetMode) {
    styles = await import("./MyLoyaltyProgramVwq.module.scss");
  } else {
    styles = await import("./MyLoyaltyProgram.module.scss");
  }
})();

const MyLoyaltyProgramTabs = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [accessToken, setAccessToken] = useState();
  const { locale } = useContext(RCLContext);
  const [starCardInfo, setStarCardInfo] = useState(null);
  const [platinumClubInfo, setPlatinumClubInfo] = useState(null);
  const [globalId, setGlobalId] = useState();
  const [totalPtnsEarned, setTotalPtnsEarned] = useState();
  const [totalPtnsUsed, setTotalPtnsUsed] = useState();
  const [starCardNo, setStarCardNo] = useState();
  const [starCashBal, setStarCashBal] = useState();
  const [isActivated, setIsActivated] = useState();
  const [isExpiring, setIsExpiring] = useState();
  const [ewalletLink, setEwalletLink] = useState();
  const [token, setToken] = useState(null);
  const [starCard, setStarCard] = useState(null);
  const [platinumClubParagraph, setPlatinumClubParagraph] = useState(null);
  const [platinumClubFAQsParagraph, setPlatinumClubFAQsParagraph] =
    useState(null);
  const [notAStarMember, setNotAStarMember] = useState(null);
  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false);
  const [redemptionRules, setRedemptionRules] = useState();
  const [activeTab, setActiveTab] = useState("tabStar-0");

  function toggleFAQModal() {
    setIsFAQModalOpen(!isFAQModalOpen);
  }

  const platinumClubParagraphQuery = `
      query paragraphEntryQuery($locale: String) {
        paragraph(id: "2ImtJc4erhUgW6wQh5HbEA", locale: $locale) {
          sys {
            id
          }
          heading
          content {
            json
          }
        }
      }
    `;
  const platinumClubFAQsParagraphQuery = `
    query paragraphEntryQuery($locale: String) {
      paragraph(id: "yEnJV0EkPYEwcG5034rD9", locale: $locale) {
        sys {
          id
        }
        heading
        content {
          json
        }
      }
    }
  `;

  const notAStarMemberQuery = `
        query paragraphEntryQuery($locale: String) {
          paragraph(id: "6tqSLtUi7u6DRfoyDj9n1D", locale: $locale) {
            sys {
              id
            }
            heading
            content {
              json
            }
          }
        }
      `;

  const getPlatinumClubParagraph = async (token, locale) => {
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: platinumClubParagraphQuery,
        variables: { locale: locale === "fr" ? locale : "en-CA" },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setPlatinumClubParagraph(data?.paragraph);
      });
  };

  const getPlatinumClubFAQsParagraph = async (token, locale) => {
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: platinumClubFAQsParagraphQuery,
        variables: { locale: locale === "fr" ? locale : "en-CA" },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setPlatinumClubFAQsParagraph(data?.paragraph);
      });
  };

  const getNotAStarMember = async (token, locale) => {
    await fetch(config.resourceServer.contentfulApimAgentGqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: notAStarMemberQuery,
        variables: { locale: locale === "fr" ? locale : "en-CA" },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setNotAStarMember(data?.paragraph);
      });
  };

  const getStarCardInfo = async (token) => {
    await fetch(`${config.resourceServer.agentApiUrl}/loyalty`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setStarCardInfo(data);
        setGlobalId(data.starAgentInfo?.enterpriseReferenceKey);
        setTotalPtnsEarned(data.starCard?.totalPtnsEarned);
        setTotalPtnsUsed(data.starCard?.totalPtnsUsed);
        setStarCardNo(data.starCard?.starCardNo);
        setStarCashBal(data.starCard?.starCashBal);
        setIsActivated(data.starCard?.isActivated);
        setIsExpiring(data.starCard?.isExpiring);
        setEwalletLink(data.starCard?.ewalletLink);
      });
  };

  const updateStarCardInfo = async (callback) => {
    await getStarCardInfo(accessToken);
    setStarCard(null);

    if (typeof callback === "function") {
      callback();
    }
    setStarCard(<StarCard locale={locale} />);
  };

  const getPlatinumClubInfo = async (token) => {
    await fetch(
      `${config.resourceServer.agentApiUrl}/loyalty/PlatinumClubInfo`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setPlatinumClubInfo(data);
      });
  };

  const getRedemptionRules = async (token) => {
    await fetch(`${config.resourceServer.agentApiUrl}/loyalty/card/redemption`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        setRedemptionRules(data);
      });
  }


  const updateActiveTab = () => {
    let params =  new URLSearchParams(window.location.search);
    let activeTab = params.get("activeTab");

    if (activeTab === "1" || config.isWestJetMode)
      setActiveTab("tabStar-1");
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      updateActiveTab();
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        setAccessToken(oktaAuth.getAccessToken());
        getStarCardInfo(oktaAuth.getAccessToken());
        getPlatinumClubInfo(oktaAuth.getAccessToken());
        setToken(oktaAuth.getAccessToken());
        setStarCard(<StarCard locale={locale} />);
        getPlatinumClubParagraph(oktaAuth.getAccessToken(), locale);
        getPlatinumClubFAQsParagraph(oktaAuth.getAccessToken(), locale);
        getNotAStarMember(oktaAuth.getAccessToken(), locale);
        getRedemptionRules(oktaAuth.getAccessToken());

      });
    }
  }, [authState, oktaAuth, locale]);

  const labels = {
    starProgram: RCL({ searchKey: "star_program" }),
    bookingActivity: RCL({ searchKey: "star_activity" }),
    howPointsAreAwarded: RCL({ searchKey: "howPointsAreAwarded" }),
    flightSegments: RCL({ searchKey: "flightSegments" }),
    hotelRooms: RCL({ searchKey: "hotelRooms" }),
    carRentals: RCL({ searchKey: "carRentals" }),
    excursions: RCL({ searchKey: "excursions" }),
    insurancePlans: RCL({ searchKey: "insurancePlans" }),
    bookings: RCL({ searchKey: "bookings" }),
    oneMonth: RCL({ searchKey: "1month" }),
    sixMonths: RCL({ searchKey: "6months" }),
    twelveMonths: RCL({ searchKey: "12months" }),
    currentPointsBalance: RCL({ searchKey: "currentPointsBalance" }),
    minimumRedemption1: RCL({ searchKey: "minimumRedemption" }),
    yourPoints: RCL({ searchKey: "yourPoints" }),
    note: RCL({ searchKey: "notes-ap" }),
    renewYourSTARCard: RCL({ searchKey: "renewYourSTARCard" }),
    loadYourSTARCard: RCL({ searchKey: "loadYourSTARCard" }),
    rateOf: RCL({ searchKey: "rateof" }),
    pointMinimum: RCL({ searchKey: "pointminimum" }),
    maxPointsValue: RCL({ searchKey: "maxPointsValue" }),
    enterPointsToRedeem: RCL({ searchKey: "enterPointsToRedeem" }),
    starLoyaltyProgram: RCL({ searchKey: "starLoyaltyProgram" }),
    starCardNumber: RCL({ searchKey: "starCardNumber" }),
    cashValue: RCL({ searchKey: "cashValue" }),
    loadCash: RCL({ searchKey: "loadCash" }),
    backToBalance: RCL({ searchKey: "backToBalance" }),
    modalClose: RCL({ searchKey: "modal-close" }),
    myPointsTransactions: RCL({ searchKey: "myPointsTransactions" }),
    platinumClub: RCL({ searchKey: "platinumclub" }),
    month: RCL({ searchKey: "month" }),
    minimumRedemption: RCL({ searchKey: "minimumredemption" }),
    pointsOr: RCL({ searchKey: "pointsor" }),
    btnYes: RCL({ searchKey: "yes" }),
    btnNo: RCL({ searchKey: "no" }),
    renewalConfirmation: RCL({ searchKey: "loyalty_card_renewal_confirmation" }), 
  };

  if (!userInfo && starCardInfo && isActivated !== undefined) {
    return (
      <div className="star-balance__loader">
        <Loader />
      </div>
    );
  }

  const starTabsData = [
    {
      key: "tabStar-0",
      toggle: {
        label: {
          heading: labels.starProgram,
        },
      },
      panel: (
        <>
          <Heading as="h2">
            <RCL searchKey="star_program" />
          </Heading>
          {starCardInfo && starCardInfo.isStarRegistered && starCardNo ? (
            <>
              <div className="star-balance__main-container">
                <StarStats
                  labels={{
                    bookingActivity: labels.bookingActivity,
                    howPointsAreAwarded: labels.howPointsAreAwarded,
                    flightSegments: labels.flightSegments,
                    hotelRooms: labels.hotelRooms,
                    carRentals: labels.carRentals,
                    excursions: labels.excursions,
                    insurancePlans: labels.insurancePlans,
                    bookings: labels.bookings,
                  }}
                  filters={[
                    {
                      label: labels.oneMonth,
                      value: "-1",
                    },
                    {
                      label: labels.sixMonths,
                      value: "-6",
                    },
                    {
                      label: labels.twelveMonths,
                      value: "-12",
                    },
                  ]}
                />
                {isActivated ? (
                  <StarBalance
                    refreshData={updateStarCardInfo}
                    {...{
                      initialData: totalPtnsEarned - totalPtnsUsed,
                      globalCanLoadCard: RCL({
                        searchKey: "globalCanLoadCard",
                      }),
                      config: {
                        locale: locale,
                        labels: {
                          currentPointsBalance: labels.currentPointsBalance,
                          minimumRedemption: labels.minimumRedemption1,
                          yourPoints: labels.yourPoints,
                          note: labels.note,
                          balanceNote: `${labels.minimumRedemption} {{minPoints}} ${labels.pointsOr} {{minPointsValue}}.`,
                          btnYes:labels.btnYes,
                          btnNo:labels.btnNo,
                          renewalConfirmation:labels.renewalConfirmation,
                        },
                        footer: {
                          loadStar: {
                            initialData: {
                              AgentGlobalId: globalId,
                              MinPntsRedeem: redemptionRules.minPntsRedeem,
                              MaxPntsRedeem: redemptionRules.maxPntsRedeem,
                              PointRate: redemptionRules.pointRate,
                            },
                            STARData: {
                              StarCardNo: starCardNo,
                              TotalPtnsEarned: totalPtnsEarned,
                              TotalPtnsUsed: totalPtnsUsed,
                              STARCashBal: starCashBal,
                              IsActivated: isActivated,
                              IsExpiring: isExpiring,
                              EwalletLink: ewalletLink,
                            },
                            urlPost: `${config.resourceServer.agentApiUrl}/loyalty/Card/Redemption`,
                            token: token,
                            labels: {
                              renewYourSTARCard: labels.renewYourSTARCard,
                              loadYourSTARCard: labels.loadYourSTARCard,
                              redemptionRules: `${labels.rateOf} {{rate}} ${labels.pointMinimum} {{minPoints}} ${labels.pointsOr} {{minPointsValue}}. ${labels.maxPointsValue} {{maxPointsValue}}.`,
                              enterPointsToRedeem: labels.enterPointsToRedeem,
                              starLoyaltyProgram: labels.starLoyaltyProgram,
                              STARCardNumber: labels.starCardNumber,
                              cashValue: labels.cashValue,
                              loadCash: labels.loadCash,
                              backToBalance: labels.backToBalance,
                              close: labels.modalClose,
                            },
                            returnUrl: `/${locale}/my-loyalty-program`,
                          },
                          myPointsTransactions: {
                            labels: {
                              myPointsTransactions: labels.myPointsTransactions,
                            },
                            url: `/${locale}/transactions`,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <ActivateStarCard
                    AgentGlobalId={globalId}
                    starCardNo={starCardNo}
                    setIsActivated={setIsActivated}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="star-balance__not-registered">
              <Row cols={2}>
                <Cell className="star-balance__not-registered-text">
                  {notAStarMember && (
                    <ParagraphConnector
                      data={notAStarMember}
                      locale={locale === "fr" ? locale : "en-CA"}
                    />
                  )}
                </Cell>
                <Cell>
                  <Img
                    src="https://assets.sunwingtravelgroup.com/image/upload/v1637870890/sunwing-prod/Web%20Replatform%20Project/Agent%20Portal/%20Groups%20Resources/Agent-Rewards.jpg"
                    alt=""
                  />
                </Cell>
              </Row>
            </div>
          )}
          <MyLoyaltyProgramCtas
            locale={locale}
            starCardInfo={starCardInfo}
            starCard={starCard}
          />
        </>
      ),
    },
    {
      key: "tabStar-1",
      toggle: {
        label: {
          heading: labels.platinumClub,
        },
      },
      panel: (
        <>
          {platinumClubInfo && (
            <PlatinumClub
              platinumClubInfo={platinumClubInfo}
              toggleFAQModal={toggleFAQModal}
              isFAQModalOpen={isFAQModalOpen}
            ></PlatinumClub>
          )}
          <PlatinumClubCtas
            locale={locale}
            platinumClubParagraph={platinumClubParagraph}
            platinumClubFAQSParagraph={platinumClubFAQsParagraph}
            toggleFAQModal={toggleFAQModal}
            isFAQModalOpen={isFAQModalOpen}
          />
        </>
      ),
    },
  ];

  return (
    <>
      {styles && 
        (<div className={config.isWestJetMode ? styles.myLoyaltyProgramVwq: ""}>
          <Tabs
            activeKey={activeTab}
            data={starTabsData}
            tabIconLeftComponent={<Icon name="arrow-left" />}
            tabIconRightComponent={<Icon name="arrow-right" />}
            theme="generic"
            customTabWrapperClass="tabsWrapper"
            customPanelClass="tabsPanel"
            customActiveClass="tabsActive"
          />
        </div>)}
    </>
  );
};

export default MyLoyaltyProgramTabs;
export { MyLoyaltyProgramTabs };
